import React from 'react';

import Image from 'next/image';

import {
  Box,
  Button,
  Typography
} from '@mui/material';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import axiosInstance from '../../lib/axiosInstance';


const checkEmail = async (value) => {
  const { data: { existing } } = await axiosInstance(
    `/api/v2/check-email/`, {
      params: {
        email: value
      }
    }
  )
  return existing
}

const ResetSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email')
    .test(
      'emailExists',
      'No existing user found with this email',
      checkEmail
    ),
});


const ResetPassword = () => {
  
  const [sent, setSent] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  return (
    <>
    {!sent ? <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={ResetSchema}
      onSubmit={(values, { setSubmitting }) => {
        setErrorMessage(null);

        axiosInstance.post(
          `/api/v2/reset-password/`,
          values
        ).then(() => {
          setSubmitting(false);
          setSent(true);
        }).catch(error => {
          setSubmitting(false);
          if (error.response && error.response.data.email){
            setErrorMessage(error.response.data.email[0]);
          } else {
            setErrorMessage('Something went wrong');
          }
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box p={2} textAlign="center">
            Provide your email to reset your password
          </Box>
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
          />

          {errorMessage && <Box color="error.dark">{errorMessage}</Box>}

          <Box mt={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              Reset Password
            </Button>
          </Box>
        </Form>
      )}
    </Formik> :
    <Box textAlign="center" p={2}>
      <Image
        src="/icons/receive-quotes.svg"
        width={200}
        height={200}
        alt="Receive quotes"
      />
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Now check your mail</Typography>
      <Box>We sent you an email. Click the link in the email to reset your password.</Box>
    </Box>
    }
    </>
  )
}

export default ResetPassword;
