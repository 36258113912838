import React from 'react';

import {
  Box,
  Button
} from '@mui/material';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

import axiosInstance from '../../lib/axiosInstance';


YupPassword(Yup); // extend yup


const checkEmail = async (value) => {
  const { data: { existing } } = await axiosInstance(
    `/api/v2/check-email/`, {
      params: {
        email: value
      }
    }
  )
  return !existing
}

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email')
    .test(
      'emailExists',
      'A user with this email exists already',
      checkEmail
    ),
  first_name: Yup.string()
    .required('Please enter your first name'),
  last_name: Yup.string()
    .required('Please enter your last name'),
  password: Yup.string()
    .password()
    .required('Please provide your password'),
});


const EmailSignup = ({ setSent, handleSuccess }) => {
  
  const [signupError, setSignupError] = React.useState(null);

  return (
    <Formik
      initialValues={{
        email: '',
        first_name: '',
        last_name: '',
        password: ''
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSignupError(null);

        axiosInstance.post(
          `/api/v2/signup/`,
          values
        ).then(() => {
          setSubmitting(false);
          setSent(true);
          if (handleSuccess) {
            handleSuccess(values.email)
          }
        }).catch(error => {
          if (error.response && error.response.status == 400) {
            console.log(error.response.data);
            setSignupError('Something went wrong');
          }
          console.log('error', error);
          setSubmitting(false);
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box sx={{
            '& .MuiTextField-root': {
              mb: 2
            },
          }}>
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Email Address"
              variant="outlined"
              size="small"
              autoComplete="username"
              fullWidth
            />
            <Field
              component={TextField}
              name="first_name"
              label="First Name"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              component={TextField}
              name="last_name"
              label="Last Name"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              autoComplete="new-password"
              size="small"
              fullWidth
            />

            {signupError && <Box color="error.dark">{signupError}</Box>}

            <Box>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default EmailSignup;
