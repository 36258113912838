import React from 'react';

import { Box, Divider, Hidden } from '@mui/material';

import GoogleSignIn from './GoogleSignIn';
import FacebookSignIn from './FacebookSignIn';
import EmailLogin from './EmailLogin';
import ResetPassword from './ResetPassword';
import MBHeader from '../MBHeader';

import Link from '../Link';


const Login = ({ handleClose, setAuthMode, hideLogo }) => {
  

  const [resetPassword, setResetPassword] = React.useState(null);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" py={1}>
      {hideLogo ? (
        <Hidden mdUp>
          <MBHeader />
        </Hidden>
      ) : (
        <MBHeader />
      )}

      {!resetPassword ? (
        <>
          <Box maxWidth={300}>
            <EmailLogin handleClose={handleClose} setResetPassword={setResetPassword} />
          </Box>

          <Box m={2} width="100%" maxWidth={300} display="flex">
            <Box width="100%" pt={1}>
              <Divider />
            </Box>
            <Box fontWeight={500} minWidth={48} textAlign="center" color="text.secondary">
              Or
            </Box>
            <Box width="100%" pt={1}>
              <Divider />
            </Box>
          </Box>

          <Box mb={1} width="100%" maxWidth={300}>
            <GoogleSignIn googleSuccess={handleClose} />
          </Box>
          <Box my={1} width="100%" maxWidth={300}>
            <FacebookSignIn facebookSuccess={handleClose} />
          </Box>
          <Box m={2} fontSize="10px" color="text.secondary" maxWidth={300}>
            By continuing you agree to MechanicBuddy&apos;{' '}
            <Link href="/end-user-terms-and-conditions">Terms of Service</Link>
          </Box>
          <Box m={1} width="100%" maxWidth={300}>
            <Divider />
          </Box>
          <Box m={1} fontWeight={500}>
            Not on MechanicBuddy yet?{' '}
            <Box
              component="span"
              onClick={() => setAuthMode(false)}
              sx={{
                color: 'secondary.main',
                cursor: 'pointer',
                '&:hover': {
                  color: 'secondary.light',
                },
              }}
            >
              Sign up
            </Box>
          </Box>
        </>
      ) : (
        <ResetPassword />
      )}
    </Box>
  );
};

export default Login;
