import React from 'react';

import Image from 'next/image';

import { useRouter } from 'next/router';

import {
  Box,
} from '@mui/material';


const MBHeader = ({ care=false }) => {
  const router = useRouter();

  return (
    <>
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      mt={2}
      mb={4}
    >
      <Image
        width={275}
        height={35}
        src="/logos/mechanicbuddy-light.png"
        alt="MechanicBuddy"
        onClick={() => router.push('/')}
      />
    </Box>
    {care && <Box mb={2} color="#777">Car care made easier</Box>}
    </>
  )
}

export default MBHeader;
