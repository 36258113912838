import React from 'react';

import Image from 'next/image';

import {
  Box,
  Divider,
  Typography,
  Hidden,
} from '@mui/material';

import GoogleSignIn from './GoogleSignIn';
import FacebookSignIn from './FacebookSignIn';
import EmailSignup from './EmailSignup';

import Link, { NextLinkComposed } from '../Link';
import MBHeader from '../MBHeader';


const Signup = ({ handleClose, setAuthMode, hideLogo }) => {
  
  const [sent, setSent] = React.useState(null);

  const handleSuccess = () => {
    console.log('success')
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      py={1}
    >
      {hideLogo ? (
        <Hidden mdUp>
          <MBHeader />
        </Hidden>
      ) : (
        <MBHeader />
      )}

      {!sent ? <>
      <Box maxWidth={300}>
        <EmailSignup handleSuccess={handleSuccess} setSent={setSent} />
      </Box>
      
      <Box
        m={2}
        width="100%"
        maxWidth={300}
        display="flex"
      >
        <Box width="100%" pt={1}><Divider /></Box>
        <Box fontWeight={500} minWidth={48} textAlign="center" color="text.secondary">Or</Box>
        <Box width="100%" pt={1}><Divider /></Box>
      </Box>

      <Box mb={1} width="100%" maxWidth={300}>
        <FacebookSignIn facebookSuccess={handleClose} />
      </Box>
      <Box m={1} width="100%" maxWidth={300}>
        <GoogleSignIn googleSuccess={handleClose} />
      </Box>
      <Box m={2} fontSize="10px" maxWidth={300} color="text.secondary">
        By continuing you agree to MechanicBuddy&apos;s <Link href="/end-user-terms-and-conditions">Terms of Service</Link>
      </Box>
      <Box mb={1} fontWeight={500}>
        Already a member? <Box component="span" sx={{
          color: 'secondary.main',
          cursor: "pointer",
          '&:hover': {
            color: 'secondary.light',
          },
        }} onClick={() => setAuthMode(true)}>Log in</Box>
      </Box>
      <Box mt={2} width="100%" maxWidth={300}>
        <Divider />
      </Box>
      <Box
        py={2}
        width="100%"
        fontSize="h6.fontSize"
        color="primary.main"
        textAlign="center"
        to={{
          pathname: `/register-business/`,
        }}
        component={NextLinkComposed}
      >
        <Box component="span" sx={{
          cursor: "pointer",
          '&:hover': {
            color: 'secondary.main',
          },
        }}>Create a business account</Box>
      </Box>
      </> :
      <Box textAlign="center" p={2}>
        <Image
          src="/icons/receive-quotes.svg"
          width={200}
          height={200}
          alt="Receive quotes"
        />
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Now check your mail</Typography>
        <Box>We sent you a verification email. Click the link in the email to complete the signup process.</Box>
      </Box>
      }
    </Box>
  )
}

export default Signup;
