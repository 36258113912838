import React, { useState } from 'react';

import {
  Button,
} from '@mui/material';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import FacebookIcon from '@mui/icons-material/Facebook'

import useAuth from '../../contexts/auth.js';;


const FacebookSignIn = ({facebookSuccess}) => {
  
  const { facebookLogin } = useAuth();
  const [loading, setLoading] = useState(false)

  // submit token for auth and user info
  const loginUser = ({ accessToken }) => {
    setLoading(true);
    facebookLogin(accessToken).then(() => {
      facebookSuccess();
      setLoading(false);
    }).catch(error => {
      console.log('error', error);
      setLoading(false);
    })
  }

  return <FacebookLogin
    appId="376137779203406"
    // autoLoad={true}
    fields="name,email,picture"
    callback={loginUser}
    disableMobileRedirect={true}
    render={({onClick, isDisabled, isProcessing, isSdkLoaded}) => (
      <Button
        variant="contained"
        color="primary"
        startIcon={<FacebookIcon />}
        onClick={onClick}
        disabled={isDisabled || isProcessing || !isSdkLoaded || loading}
        sx={{
          background: '#4267b2',
          width: "100%",
          color: 'white',
          '&:hover': {
            bgcolor: '#898F9C'
          }
        }}
      >
        {!loading ? "Continue with Facebook" : "Loading ... "}
      </Button>
    )}
  />
}

export default FacebookSignIn;
