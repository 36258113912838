import React from 'react';

import { useRouter } from 'next/router';

import {
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import Login from './Login';
import Signup from './Signup';


const AuthDialog = ({
  open,
  handleClose,
  isPage=false,
  isLogin=true,
  dialogBD=false,
  hideLogo,
}) => {
  
  const theme = useTheme();
  const router = useRouter();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const maxWidth = useMediaQuery(theme.breakpoints.up('md')) ? 'xs' : false;

  const [authMode, setAuthMode] = React.useState(isLogin);

  const switchMode = login => {
    if (isPage) {
      if (login) {
        router.push('/login/');
      } else {
        router.push('/signup/');
      }
    } else {
      setAuthMode(login);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      sx={dialogBD ? {
        left: {
          md: '50%',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
      } : undefined}
    >
      <DialogContent>
        {!isPage && (
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: theme => theme.spacing(1),
              top: theme => theme.spacing(1),
              color: theme => theme.palette.grey[500],
            }}
            onClick={() => handleClose()}
            size="large">
            <CloseIcon />
          </IconButton>
        )}
        {authMode ? (
          <Login handleClose={handleClose} setAuthMode={switchMode} hideLogo={hideLogo} />
        ) : (
          <Signup handleClose={handleClose} setAuthMode={switchMode} hideLogo={hideLogo} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialog;
