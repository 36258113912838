import React from 'react';

import {
  Box,
  Button
} from '@mui/material';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import useAuth from '../../contexts/auth.js'


const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  password: Yup.string()
    .min(8)
    .max(256)
    .required('Please provide your password'),
});


const EmailLogin = ({handleClose, setResetPassword}) => {
  
  const [loginError, setLoginError] = React.useState(null);
  const { emailLogin } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        setLoginError(null);

        emailLogin(values).then(() => {
          handleClose();
        }).catch(error => {
          if (error.response && error.response.status == 400) {
            if (error.response && error.response.data.non_field_errors) {
              setLoginError(error.response.data.non_field_errors[0]);
            } else {
              setLoginError('Something went wrong')
            }
          }
          setSubmitting(false);
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Box
          sx={{
            '& .MuiTextField-root': {
              mb: 2
            },
          }}
        >
          <Form>
            <Field
              component={TextField}
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              size="small"
              autoComplete="username"
              fullWidth
            />
            <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              size="small"
              autoComplete="current-password"
              fullWidth
            />

            {loginError && <Box color="error.dark">{loginError}</Box>}
            <Box
              mt={-1}
              onClick={() => setResetPassword(true)}
              sx={{
                color: 'primary.light',
                cursor: "pointer",
                '&:hover': {
                  color: 'primary.main',
                },
                fontSize: "12px"
              }}
            >
              Forgot your password?
            </Box>

            <Box mt={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
                type="submit"
              >
                Log in
              </Button>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export default EmailLogin;
